exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alberta-js": () => import("./../../../src/pages/alberta.js" /* webpackChunkName: "component---src-pages-alberta-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-canada-js": () => import("./../../../src/pages/canada.js" /* webpackChunkName: "component---src-pages-canada-js" */),
  "component---src-pages-cemeteries-js": () => import("./../../../src/pages/cemeteries.js" /* webpackChunkName: "component---src-pages-cemeteries-js" */),
  "component---src-pages-census-game-js": () => import("./../../../src/pages/census-game.js" /* webpackChunkName: "component---src-pages-census-game-js" */),
  "component---src-pages-commons-js": () => import("./../../../src/pages/commons.js" /* webpackChunkName: "component---src-pages-commons-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-hire-js": () => import("./../../../src/pages/hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-montreal-js": () => import("./../../../src/pages/montreal.js" /* webpackChunkName: "component---src-pages-montreal-js" */),
  "component---src-pages-moosejaw-js": () => import("./../../../src/pages/moosejaw.js" /* webpackChunkName: "component---src-pages-moosejaw-js" */),
  "component---src-pages-nc-js": () => import("./../../../src/pages/nc.js" /* webpackChunkName: "component---src-pages-nc-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-saskatchewan-js": () => import("./../../../src/pages/saskatchewan.js" /* webpackChunkName: "component---src-pages-saskatchewan-js" */),
  "component---src-pages-saskatoon-js": () => import("./../../../src/pages/saskatoon.js" /* webpackChunkName: "component---src-pages-saskatoon-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-toronto-js": () => import("./../../../src/pages/toronto.js" /* webpackChunkName: "component---src-pages-toronto-js" */),
  "component---src-pages-waverley-js": () => import("./../../../src/pages/waverley.js" /* webpackChunkName: "component---src-pages-waverley-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

